





















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'
import moment from 'moment/moment'
import { isArray } from 'lodash'

@Component
export default class DateField extends Vue {
  private maxDate = null

  @Prop({ default: null }) private label: string
  @Prop({ type: [String, Array], default: null }) private value: string | string[]
  @Prop({ type: Boolean, default: true }) private autoClose: boolean
  @Prop({ type: Boolean, default: false }) private range: boolean
  @Prop({ type: String, default: null }) private max: string
  @Prop({ type: Boolean, default: true }) private allowFutureDate: boolean
  @Prop({ type: String, default: null }) private classes: string

  private created() {
    if (this.max === null || this.max === undefined) {
      if (this.allowFutureDate === false || this.allowFutureDate === undefined) {
        this.maxDate = moment().format('YYYY-MM-DD')
      }
    } else {
      this.maxDate = this.max
    }
  }

  private get locale() {
    let locale = vxm?.user?.language
    if (locale) {
      locale = locale.toLowerCase().replace('_', '-')
    } else {
      locale = 'en-us'
    }
    return locale
  }

  private get input() {
    return this.value
  }

  private set input(value) {
    if (isArray(value)) {
      value = value.sort()
    }
    this.$emit('input', value)
    if (this.autoClose) {
      this.getDateInput().isActive = false
    }
  }

  private getDateInput() {
    return this.$refs.dateInput as Vue & { isActive: boolean }
  }
}
